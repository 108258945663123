import React, { useState, useEffect } from 'react';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { PageProps } from 'modules/Layout/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import { useDispatch } from 'react-redux';
import Form from 'modules/Office/component/EmailForm';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { Button, Form as FormStrap } from 'reactstrap';
import CardBox from 'modules/Layout/component/CardBox';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { FetchEmailRequest } from 'modules/Office/type';
import { fetchEmail, updateEmail } from 'modules/Office/repository';
import { breadcrumbRouteOfficeEmail } from 'modules/Office/breadcrumbs';
import { officeEmailUpdateToastSuccess } from 'modules/Office/toasts';
import { Link } from 'react-router-dom';
import { ROUTE_OFFICES_EMAIL_RESEND } from 'modules/Office/routes';
import useManageFileAttachments from 'modules/Office/component/EmailForm/manageFileAttachmentsHook';
import { serialize } from 'object-to-formdata';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

const OfficeEmail = () => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const [email, setEmail] = useState<FetchEmailRequest>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const { displayWysiwyg, getContent } = useWysiwyg({
    title: 'Treść',
    content: email?.content,
    tooltip: { specialSign: true }
  });

  const saveRawContent = (data: FetchEmailRequest) => {
    const { content, ...restData } = data;

    if (typeof content === 'string') {
      setEmail({ ...restData, content });
    } else {
      setMessage({
        value: 'Zły format treści emaila.',
        variant: ColorVariants.Danger
      });
      setEmail({ ...restData });
    }
  };

  const cancelToken = useCancelToken();
  const getOfficeEmail = async () => {
    setFetching(true);
    const { cancelled, message: messageResponse, data } = await fetchRequest(fetchEmail, cancelToken);

    if (cancelled) return;
    if (data) {
      saveRawContent(data.data);
    }
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    managePage({
      title: 'Komunikacja z urzędami - email',
      breadcrumb: breadcrumbRouteOfficeEmail()
    });

    getOfficeEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { jsxAttachmentsDropbox, filesObj, filesToRemove, clearValues } = useManageFileAttachments(email?.files ?? []);

  const dispatchUpdateEmailOfficeToast = () => dispatch(addToastAction(officeEmailUpdateToastSuccess()));

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);
    const { files: _, content: q_, ...restValues } = email;

    let sendObj: FetchEmailRequest = { content: getContent(), ...restValues };

    if (filesObj?.files?.length) {
      sendObj = { ...sendObj, files: filesObj.files.map((file) => file.file) as any };
    }

    if (filesToRemove?.length) {
      sendObj = { ...sendObj, files_to_delete: filesToRemove.map((el) => el.id) };
    }

    const {
      cancelled,
      message: messageResponse,
      data,
      error
    } = await updateRequestWithData(updateEmail, serialize(sendObj), cancelToken);
    if (cancelled) return;
    if (data) {
      saveRawContent(data.data);
      dispatchUpdateEmailOfficeToast();
      clearValues();
    }
    if (error) setErrors(error);
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  if (fetching) return <Loader />;
  if (!fetching && !email)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  return (
    <div className="profile-update-view row justify-content-center">
      <div className="col-lg-10 position-relative">
        <FormStrap onSubmit={onSubmit}>
          <CardBox
            form="small"
            heading="Aktualizuj email"
            actions={
              <Link to={ROUTE_OFFICES_EMAIL_RESEND} color="link" className="link-with-border p-0 m-0">
                Wyślij maila do urzędów
              </Link>
            }
          >
            {loading && <Loader />}
            <Alert message={message} />
            <Form values={email} setValues={setEmail} displayWysiwyg={displayWysiwyg} errors={errors}>
              {jsxAttachmentsDropbox}
            </Form>
            <div className="form-actions">
              <Button
                type="submit"
                color="primary"
                className="pzpeu-btn-disabled pzpeu-btn-flex waves-effect waves-light"
                disabled={loading}
              >
                Zapisz
              </Button>
            </div>
          </CardBox>
        </FormStrap>
      </div>
    </div>
  );
};

export default OfficeEmail;
