import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message, ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button, CustomInput, FormFeedback, Input } from 'reactstrap';
import TextNumberInput from 'modules/Layout/component/Input/TextNumber/input';
import { getError, hasError } from 'modules/Shared/helper/validation';
import nipValidate from 'modules/Shared/helper/nipValidation';
import { formatDate } from 'modules/Shared/helper/utils';
import moment from 'moment';
import './style.scss';

interface IProps extends Props {
  date_to?: string;
  onUseCreditClick?: (data: { date_to: string; nip: string; foreign_nip?: boolean }) => Promise<void>;
  loading?: boolean;
  hasSubscriptionTo?: string;
  hasAlreadyBoughtAccessTo?: (nip: string) => string;
  message?: Message;
  cleanMessage?: () => void;
  errors?: ValidationErrors;
}

const ModalUseCredit: React.FC<IProps> = (props) => {
  const {
    date_to,
    onUseCreditClick,
    loading,
    toggle,
    message,
    cleanMessage,
    errors,
    hasSubscriptionTo,
    hasAlreadyBoughtAccessTo
  } = props;

  const [nip, setNip] = useState('');
  const [foreignNip, setForeignNip] = useState(false);
  const [nipError, setNipError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const title = 'Przyznanie dostępu do wykonawcy';

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) cleanMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, errors]);

  useEffect(() => {
    if (nipError) setNipError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nip]);

  const nipInput = !foreignNip ? (
    <TextNumberInput
      placeholder="NIP"
      value={nip}
      setValue={(value) => setNip(value)}
      hasError={hasError(errors, 'nip') || nipError}
    />
  ) : (
    <Input
      type="text"
      placeholder="NIP"
      value={nip}
      onChange={(value) => setNip(value.target.value)}
      invalid={hasError(errors, 'nip') || nipError}
    />
  );

  const onForeignNipCheckboxChange = () => {
    if (foreignNip) {
      setNip('');
    }
    setForeignNip(!foreignNip);
  };

  const dispalBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">Wpisz numer NIP wykonawcy, do którego chcesz otrzymać dostęp:</div>
        <div className="modal-body-content">
          {nipInput}
          <div className="d-flex flex-wrap align-items-center mt-2">
            <CustomInput
              id="foreign_nip"
              type="checkbox"
              className="executor-checkbox-use_credit"
              label={<div className="mt-1">NIP firmy zagranicznej (zezwolenie użycia wartości alfabetycznych)</div>}
              checked={foreignNip}
              onChange={onForeignNipCheckboxChange}
            />
          </div>
        </div>

        {hasError(errors, 'nip') && <FormFeedback className="d-block">{getError(errors, 'nip')}</FormFeedback>}
        {nipError && <div className="error-message">Niepoprawny format NIP-u.</div>}
        <div className="modal-body-text">
          Jeśli podany NIP jest poprawny dostępny kredyt zostanie odjęty, a Ty otrzymasz dostęp do szczegółów wybranego
          wykonawcy do dnia: <strong>{formatDate(date_to)}</strong>.
        </div>
      </>
    );
  };

  const requestBasedOnForeignNip = () => {
    const obj = { date_to, nip };
    if (foreignNip) onUseCreditClick({ ...obj, foreign_nip: foreignNip });
    else onUseCreditClick(obj);
  };

  const onSubmitClick = () => {
    setConfirmation(false);
    requestBasedOnForeignNip();
  };

  const displayConfirmationBody = () => {
    let displayConfirmationMessage = '';

    const hasCreditDateTo = hasAlreadyBoughtAccessTo(nip);

    if (hasSubscriptionTo && hasCreditDateTo) {
      if (moment(hasSubscriptionTo).isSameOrAfter(hasCreditDateTo)) {
        displayConfirmationMessage = `Posiadasz aktywny abonament na pełny dostęp dla wszystkich wykonawców do dnia ${formatDate(
          hasSubscriptionTo
        )}!`;
      } else {
        displayConfirmationMessage = `Posiadasz aktywny wykupiony dostęp dla tego wykonawcy do dnia ${formatDate(
          hasCreditDateTo
        )}!`;
      }
    } else if (hasSubscriptionTo) {
      displayConfirmationMessage = `Posiadasz aktywny abonament na pełny dostęp dla wszystkich wykonawców do dnia ${formatDate(
        hasSubscriptionTo
      )}!`;
    } else if (hasCreditDateTo) {
      displayConfirmationMessage = `Posiadasz aktywny wykupiony dostęp dla tego wykonawcy do dnia ${formatDate(
        hasCreditDateTo
      )}!`;
    }

    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />

        <div className="modal-body-title">{displayConfirmationMessage}</div>
        <div className="modal-body-text">Czy na pewno chcesz wykupić dostęp do wykonawcy o NIP-ie</div>
        <div className="modal-body-text-large">
          <strong>{nip}</strong>
        </div>
        <div className="modal-body-text mt-0">
          do dnia: <strong>{formatDate(date_to)}?</strong>
        </div>
      </>
    );
  };

  const onBtnClick = () => {
    if (isValue.current) cleanMessage();

    const validation = foreignNip ? true : nipValidate(nip);
    if (nipError && validation) {
      setNipError(false);
    } else if (!nipError && !validation) {
      setNipError(true);
    }

    if (validation) {
      if (hasSubscriptionTo || hasAlreadyBoughtAccessTo(nip)) setConfirmation(true);
      else requestBasedOnForeignNip();
    }
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={!nip || confirmation || loading}
          onClick={onBtnClick}
        >
          Uzyskaj dostęp
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  const displayConfirmationFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading}
          onClick={onSubmitClick}
        >
          Uzyskaj dostęp
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  if (date_to) {
    return (
      <BaseModal
        {...props}
        title={title}
        body={confirmation ? displayConfirmationBody() : dispalBody()}
        footer={confirmation ? displayConfirmationFooter() : displayFooter()}
      />
    );
  }
  return null;
};

export default ModalUseCredit;
