import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorExtendedListParamsAction } from 'modules/User/action/list';
import { EXECUTORS_EXTENDED } from 'modules/User/permissions';
import { ROUTE_USER_EXECUTOR_EXTENDED } from 'modules/User/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Executor from '../../../../Executor/model/Executor';
import InfoIcon from '../../../../Layout/component/Icon/Info';

const ExecutorExtendedTable: React.FC = () => {
  const dispatch = useDispatch();
  const { executorsExtended, sort } = useSelector((state: RootState) => state.user.executorsExtendedList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorExtendedListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[EXECUTORS_EXTENDED]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  let cols: TableCol<Executor>[] = [
    {
      property: 'id',
      label: 'Numer zlecenia',
      sortable: true,
      value: (row) => redirectLink(String(row.id), row.id)
    },
    {
      property: 'first_name',
      label: 'Imię klienta',
      sortable: true,
      value: (row) => redirectLink(row.first_name, row.id)
    },
    {
      property: 'last_name',
      label: 'Nazwisko klienta',
      sortable: true,
      value: (row) => redirectLink(row.last_name, row.id)
    },
    {
      property: 'name',
      label: 'Firma klienta',
      sortable: true,
      value: (row) => row.name
    },
    {
      property: 'nip',
      label: 'NIP weryfikowanego wykonawcy',
      sortable: true,
      value: (row) => row.nip
    },
    {
      property: 'status',
      label: 'Status wykonania raportu',
      sortable: true,
      value: (row) => row.status.name
    },
    {
      property: 'created_at',
      label: 'Data zlecenia raportu',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.created_at, formatDate)
    },
    {
      property: 'updated_at',
      label: 'Data ostatniej aktualizacji raportu',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.updated_at, formatDate)
    }
  ];

  cols = [
    ...cols,
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[EXECUTORS_EXTENDED]}>
              <ActionUpdate
                className="mx-1"
                title="Przejdź do szczegółów wysyłki"
                label={<InfoIcon height="20px" />}
                to={getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED, { id: row.id })}
              />
            </Authorize>
          </div>
        );
      }
    }
  ];

  return (
    <Table
      tableProps={{ className: 'executors-extended' }}
      cols={cols}
      rows={executorsExtended}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
    />
  );
};

export default ExecutorExtendedTable;
