import { fetchExecutorExtendedRaportClaim, fetchExecutorRaport } from 'modules/Executor/repository';
import { executorRaportDownloadToastError, executorRaportDownloadToastSuccess } from 'modules/Executor/toasts';
import { addToastAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

export interface Props {
  executorId: string | number;
  className?: string;
  isReportClaim?: boolean;
}

const DownloadExecutorPdf: React.FC<Props> = ({
  executorId,
  className,
  isReportClaim = 'position-relative focus-no-outline'
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const dispatchExecutorRaportSuccessToast = () => dispatch(addToastAction(executorRaportDownloadToastSuccess()));
  const dispatchExecutorRaportFailureToast = (message: string) =>
    dispatch(addToastAction(executorRaportDownloadToastError(message)));

  const cancelToken = useCancelToken();
  const executorDownloadRaportRequest = async () => {
    if (loading) return;

    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(
      isReportClaim ? fetchExecutorExtendedRaportClaim : fetchExecutorRaport,
      executorId,
      cancelToken
    );

    if (cancelled) return;
    if (success) dispatchExecutorRaportSuccessToast();
    if (messageRes) {
      dispatchExecutorRaportFailureToast(messageRes.value);
    }
    setLoading(false);
  };

  return (
    <Button color="link" className={`${className} link-with-border p-0`} onClick={executorDownloadRaportRequest}>
      {loading && <Loader />}
      POBIERZ RAPORT
    </Button>
  );
};

export default DownloadExecutorPdf;
