import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const createOrderToastSuccess = (): AddToastPayload => ({
  header: 'Tworzenie postępowań',
  body: 'Postępowanie zostało pomyślnie utworzone.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateOrderToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Postępowanie zostało zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const orderFileDownloadToastSuccess = (): AddToastPayload => ({
  header: 'Pobieranie',
  body: 'Pomyślnie pobrano załącznik.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const orderFileDeletedToastSuccess = (): AddToastPayload => ({
  header: 'Zarządzanie plikami',
  body: 'Pomyślnie usunięto plik.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteOrderToastSuccess = (): AddToastPayload => ({
  header: 'Usuwanie postępowań',
  body: 'Pomyślnie usunięto postępowanie.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const orderFileAddToastSuccess = (): AddToastPayload => ({
  header: 'Zarządzanie plikami',
  body: 'Pomyślnie dodano pliki.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
