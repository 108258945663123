import { IFile } from '../../Shared/helper/Files/AddFiles';
import { SelectOption } from '../../Shared/type';

export type ExecutorViolationSlug = 'environmental' | 'labor_law' | 'construction_law' | 'finances' | 'BDO' | 'KOBIZE';

export type ExecutorViolation = {
  id?: string | number;
  violation_description?: string;
  slug?: ExecutorViolationSlug;
  violation_date?: string;
  authority_name?: string;
  files?: IFile[];
};

export enum EXECUTOR_VIOLATIONS_SLUGS {
  ENVIRONMENTAL = 'environmental',
  LABOR_LAW = 'labor_law',
  CONSTRUCTION_LAW = 'construction_law',
  FINANCES = 'finances',
  BDO = 'BDO',
  KOBIZE = 'KOBIZE'
}

export enum EXECUTOR_VIOLATIONS_SLUG_VALUES {
  ENVIRONMENTAL = 'Naruszenia w zakresie ochrony środowiska',
  LABOR_LAW = 'Naruszenia w zakresie prawa pracy',
  CONSTRUCTION_LAW = 'Naruszenia przepisów prawa budowlanego',
  FINANCES = 'Nałożenie korekty finansowej',
  BDO = 'Naruszenia w zakresie obowiązków związanych z BDO',
  KOBIZE = 'Naruszenia w zakresie obowiązków związanych z KOBIZE'
}

export const getViolationQuestion = (slug: ExecutorViolationSlug): string => {
  switch (slug) {
    case EXECUTOR_VIOLATIONS_SLUGS.ENVIRONMENTAL:
      return 'Czy są informacje o naruszeniu w zakresie ochrony środowiska?';
    case EXECUTOR_VIOLATIONS_SLUGS.LABOR_LAW:
      return 'Czy są informacje o naruszeniu w zakresie prawa pracy?';
    case EXECUTOR_VIOLATIONS_SLUGS.CONSTRUCTION_LAW:
      return 'Czy są informacje o naruszeniu przepisów prawa budowlanego?';
    case EXECUTOR_VIOLATIONS_SLUGS.FINANCES:
      return 'Czy są informacje o nałożeniu korekty finansowej?';
    case EXECUTOR_VIOLATIONS_SLUGS.BDO:
      return 'Czy są informacje o naruszeniu w zakresie obowiązków związanych z BDO?';
    case EXECUTOR_VIOLATIONS_SLUGS.KOBIZE:
      return 'Czy są informacje o naruszeniu w zakresie obowiązków związanych z KOBIZE?';
    default:
      return '';
  }
};

export const getViolationWysiwygTitle = (slug: ExecutorViolationSlug): string => {
  switch (slug) {
    case EXECUTOR_VIOLATIONS_SLUGS.ENVIRONMENTAL:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.ENVIRONMENTAL;
    case EXECUTOR_VIOLATIONS_SLUGS.LABOR_LAW:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.LABOR_LAW;
    case EXECUTOR_VIOLATIONS_SLUGS.CONSTRUCTION_LAW:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.CONSTRUCTION_LAW;
    case EXECUTOR_VIOLATIONS_SLUGS.FINANCES:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.FINANCES;
    case EXECUTOR_VIOLATIONS_SLUGS.BDO:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.BDO;
    case EXECUTOR_VIOLATIONS_SLUGS.KOBIZE:
      return EXECUTOR_VIOLATIONS_SLUG_VALUES.KOBIZE;
    default:
      return '';
  }
};

export const executorViolationsSlugSelectValues: SelectOption[] = [
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.ENVIRONMENTAL,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.ENVIRONMENTAL
  },
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.LABOR_LAW,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.LABOR_LAW
  },
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.CONSTRUCTION_LAW,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.CONSTRUCTION_LAW
  },
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.FINANCES,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.FINANCES
  },
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.BDO,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.BDO
  },
  {
    value: EXECUTOR_VIOLATIONS_SLUGS.KOBIZE,
    label: EXECUTOR_VIOLATIONS_SLUG_VALUES.KOBIZE
  }
];

export const executorViolationsSlugs: ExecutorViolationSlug[] = [
  'environmental',
  'labor_law',
  'construction_law',
  'finances',
  'BDO',
  'KOBIZE'
];
