import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ExecutorClient, { createExecutorClient } from 'modules/Executor/model/ExecutorClient';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteExecutor } from 'modules/Executor/breadcrumbs';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import ExecutorDetails from 'modules/Executor/component/Details/Client';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchExecutorReportClaim } from 'modules/Executor/repository';
import { analytics } from 'firebase';
import useExecutorExplanationSectionWrapper from 'modules/Executor/container/useExecutorExplanationSectionWrapper';
import ModalExecutorExplanantion from 'modules/Executor/component/Modal/DisplayExecutorExplanantion';
import {
  COMPLAINT_SLUG,
  MISREPRESENTATION_SLUG,
  PENALTIES_SLUG,
  TERMINATION_SLUG
} from 'modules/ExecutorOrder/model/Order';
import { push } from 'connected-react-router';
import { ROUTE_EXECUTORS_REPORT_CLAIMS } from 'modules/Executor/routes';

const ReportClaimDetails = () => {
  const dispatch = useDispatch();
  const { report_claim_id } = useParams<{ report_claim_id: string }>();
  const [executor, setExecutor] = useState<ExecutorClient>(null);
  const [message, setMessage] = useState<Message>(null);
  const [displayExecutorExplanationModal, setDisplayExecutorExplanationModal] = useState('');
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse,
        status
      } = await fetchRequest(fetchExecutorReportClaim, report_claim_id, cancelToken);

      if (status === 403) {
        dispatch(push(ROUTE_EXECUTORS_REPORT_CLAIMS));
        return;
      }

      if (cancelled) return;
      if (data) setExecutor(createExecutorClient(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    analytics.logEvent('executor_activation');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executor) {
      const executorName = executor?.executor_data.name?.trim() || `NIP: ${executor?.executor_data?.nip}`;
      const title = `${executorName} - szczegóły`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteExecutor(report_claim_id, false, executorName)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor]);

  const {
    explanationSection,
    fetching: explanationFetching,
    message: explanationMessage
  } = useExecutorExplanationSectionWrapper(!!displayExecutorExplanationModal);

  const sectionName = () => {
    switch (displayExecutorExplanationModal) {
      case PENALTIES_SLUG:
        return 'Naliczenie kar umownych';
      case TERMINATION_SLUG:
        return 'Rozwiązanie umowy';
      case COMPLAINT_SLUG:
        return 'Wytoczenie powództwa w związku z realizacją umowy';
      case MISREPRESENTATION_SLUG:
        return 'Wprowadzenie zamawiającego w błąd';
      default:
        return '';
    }
  };

  if (fetching) return <Loader />;

  if (!fetching && !executor && message)
    return (
      <div className="executor-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (!fetching && executor) {
    return (
      <div className="row executor-details-view justify-content-center position-relative">
        {displayExecutorExplanationModal && (
          <ModalExecutorExplanantion
            sectionName={sectionName()}
            content={explanationSection?.[displayExecutorExplanationModal]}
            loading={explanationFetching}
            message={explanationMessage}
            toggle={() => setDisplayExecutorExplanationModal(null)}
            isOpen={!!displayExecutorExplanationModal}
          />
        )}
        <div className="col-md-10 col-lg-8 col-xl-6 user-details">
          <ExecutorDetails
            executor={executor}
            isReportClaim
            report_claim_id={report_claim_id}
            setExecutorExplanationModal={(slug: string) => setDisplayExecutorExplanationModal(slug)}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default ReportClaimDetails;
