import { BreadcrumbItem } from 'modules/Layout/type';
import { breadcrumbRouteExecutor } from 'modules/Executor/breadcrumbs';

/*
export const breadcrumbOffices = (): BreadcrumbItem => ({
  title: 'Urzędy',
  link: ROUTE_OFFICES,
  permissions: [OFFICES_INDEX]
});
*/

export const breadcrumbRouteOrderCreate = (executor_id: number | string, executorName: string): BreadcrumbItem[] => [
  ...breadcrumbRouteExecutor(executor_id, true, executorName),
  {
    title: 'Dodawanie postępowania'
  }
];

export const breadcrumbRouteOrderDetails = (
  executor_id: number | string,
  name: string,
  executorName: string,
  url = ''
): BreadcrumbItem[] => [
  ...breadcrumbRouteExecutor(executor_id, true, executorName),
  {
    title: `Postępowanie${name ? ` - ${name}` : ''}`,
    link: url
  }
];

export const breadcrumbRouteOrderUpdate = (
  executor_id: number | string,
  name: string,
  executorName: string,
  url?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteOrderDetails(executor_id, name, executorName, url),
  {
    title: `Edycja postępowania${name ? ` - ${name}` : ''}`
  }
];
