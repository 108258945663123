import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import ForeignProceedingsFieldset from 'modules/ForeignProceedings/component/Fieldset/Main';
import { EditorState } from 'react-draft-wysiwyg';
import { ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN } from 'modules/ForeignProceedings/routes';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';
import ForeignProceedings, { ForeignProceedingSubmitData } from '../../model/ForeignProceedings';

export type WysiwygValuesType = {
  socialClausesObj: EditorState;
  orderObjectObj: EditorState;
  otherContactContentObj: EditorState;
  answerPlaceObj: EditorState;
  answerWayObj: EditorState;
};

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  forPrData?: ForeignProceedings;
  submit: (data: ForeignProceedingSubmitData) => void;
  isEditing?: boolean;
  cancelRedirect?: string;
}

const Form: React.FC<Props> = ({ errors, disabled, submit, forPrData, isEditing, cancelRedirect }) => {
  const [values, setValues] = useState<ForeignProceedings>({
    name: forPrData?.name ?? '',
    slug_source: forPrData?.slug_source ?? '',
    status: forPrData?.status ?? 1,
    ref_nr: forPrData?.ref_nr ?? '',
    ordered_by: forPrData?.ordered_by ?? '',
    ordered_by_info: forPrData?.ordered_by_info ?? '',
    href: forPrData?.href ?? '',
    procedure: forPrData?.procedure ?? '',
    platform_mode: forPrData?.platform_mode ?? '',
    verification: forPrData?.verification ?? 0,
    description: forPrData?.description ?? '',
    deadline_date: forPrData?.deadline_date ?? '',
    published_date: forPrData?.published_date ?? '',
    links: forPrData?.links ?? [],
    codes: forPrData?.codes ?? [],
    countries: forPrData?.countries ?? [],
    industries: forPrData?.industries ?? [],
    country_code: forPrData?.country_code ?? '',
    files: forPrData?.files ?? []
  });
  const { displayWysiwyg: orderObjectWysiwyg, getContent: orderObjectContent } = useWysiwyg({
    title: 'Opis przedmiotu zamówienia',
    content: forPrData?.description,
    tooltip: { specialSign: true }
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const order_object = orderObjectContent();

    const {
      proceeding_type,
      status,
      offers_send_date,
      requires_deposit,
      deposit,
      answer_place,
      answer_way,
      start_date_type,
      countries,
      industries,
      ...rest
    } = values;

    submit({
      status: String(status),
      order_object,
      offers_send_date,
      requires_deposit,
      deposit: requires_deposit ? deposit : null,
      countries: countries.map((c) => {
        return c.code;
      }),
      industries: industries.map((i) => {
        return i.industry_type_id ? i.industry_type_id : i.id;
      }),
      ...rest
    });
  };

  const hasValue = () => !Object.values(values).some(Boolean);
  return (
    <FormStrap className="foreign-proceeding-form" onSubmit={onSubmit}>
      <ForeignProceedingsFieldset
        disabled={disabled}
        orderObjectWysiwyg={orderObjectWysiwyg}
        errors={errors}
        values={values}
        setValues={setValues as any}
        isEditing={isEditing}
        required
      />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <Button
            type="submit"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light"
            disabled={hasValue() || disabled}
          >
            Zapisz
          </Button>
          <Link to={cancelRedirect ?? ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN} className="cancel waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default Form;
