import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { CompanyEntity, UserEntity } from 'modules/User/model/User';
import { CreateUserValues, ProfileValues, UserChangesValues } from 'modules/Profile/type';
import { UserRoleEntity } from 'modules/User/model/UserRole';
import { InvoiceRequiredEntity } from 'modules/User/model/Invoice';
import { ExecutorEntity } from '../Executor/model/Executor';

export const API_USERS = '/api/users';
export const API_USERS_EXECUTORS_EXTENDED = '/api/executors/report_claims';
export const API_USERS_CRUD = '/api/users/:id';
export const API_USER_UPDATE_BASE = '/api/users/:id/basic';
export const API_USER_UPDATE_COMPANY = '/api/users/:id/company';
export const API_ROLE_LIST = '/api/roles';
export const API_CHANGES_LIST = '/api/users/:id/audits';
export const API_INVOICES = '/api/payments/with_invoice';
export const API_USER_ACCEPT_NIP = '/api/users/:id/accept_nip';

export type FetchUsersResponse = {
  data: UserEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchExecutorsExtendedResponse = {
  data: ExecutorEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchUsers = (params: SearchParams = {}): AxiosPromise<FetchUsersResponse> =>
  axios.get(getAPIUrl(API_USERS, null, params));

export const fetchUsersExecutorsExtended = (params: SearchParams = {}): AxiosPromise<FetchExecutorsExtendedResponse> =>
  axios.get(getAPIUrl(API_USERS_EXECUTORS_EXTENDED, null, params));

export type FetchUserResponse = {
  data: UserEntity;
};

export const fetchUser = (id: number | string, cancelToken?: any): AxiosPromise<FetchUserResponse> =>
  axios.get(getAPIUrl(API_USERS_CRUD, { id }), { cancelToken });

export const createNewUser = (data: CreateUserValues, cancelToken?: any): AxiosPromise<FetchUserResponse> =>
  axios.post(getAPIUrl(API_USERS), data, { cancelToken });

export const deleteUser = (id: number | string, cancelToken?: any): AxiosPromise<Record<string, unknown>> =>
  axios.delete(getAPIUrl(API_USERS_CRUD, { id }), { cancelToken });

export type UpdateUserResponse = {
  data: UserEntity;
};

export const updateUserBase = (
  id: number | string,
  data: ProfileValues,
  cancelToken?: any
): AxiosPromise<FetchUserResponse> => axios.put(getAPIUrl(API_USER_UPDATE_BASE, { id }), data, { cancelToken });

export const updateUserCompany = (
  id: number | string,
  data: CompanyEntity,
  cancelToken?: any
): AxiosPromise<FetchUserResponse> => axios.put(getAPIUrl(API_USER_UPDATE_COMPANY, { id }), data, { cancelToken });

export const fetchRoleList = (cancelToken?: any): AxiosPromise<{ data: UserRoleEntity[] }> =>
  axios.get(getAPIUrl(API_ROLE_LIST), { cancelToken });

export type ChangesListResponse = {
  data: UserChangesValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchChangesList = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ChangesListResponse> => axios.get(getAPIUrl(API_CHANGES_LIST, { id }, params), { cancelToken });

export type FetchInvoicesResponse = {
  data: InvoiceRequiredEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchInvoices = (params: SearchParams = {}): AxiosPromise<FetchInvoicesResponse> =>
  axios.get(getAPIUrl(API_INVOICES, null, params));

export const acceptUserNip = (id: number | string, cancelToken?: any): AxiosPromise<FetchUserResponse> =>
  axios.get(getAPIUrl(API_USER_ACCEPT_NIP, { id }), { cancelToken });
