import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import React from 'react';
import { Route } from 'react-router-dom';
import JudgementClientListView from 'modules/JudgementClient/view';
import JudgementClientDetails from 'modules/JudgementClient/view/Details';
import ModuleGuard from 'modules/Auth/container/Guard/ModuleGuard';
import { MODULE_JUDGEMENTS } from 'modules/Module/model/Module';
import { JUDGEMENT_MODULE_ACCESS } from './permissions';
import { JUDGMENTS_MODULE_ADMIN_ACCESS } from '../Judgement/permissions';

export const ROUTE_JUDGEMENT_CLIENT = '/judgment/:id(\\d+)';
export const ROUTE_JUDGEMENT_CLIENT_LIST = '/judgment/list';

export const createJudgementClientRoutes = (): Routes => ({
  wrapped: [
    <Authorize
      key="judgement-client-details"
      permissions={[JUDGEMENT_MODULE_ACCESS]}
      hasAccess={(user) => !user?.hasPermissions([JUDGMENTS_MODULE_ADMIN_ACCESS])}
    >
      <Route
        path={ROUTE_JUDGEMENT_CLIENT}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_JUDGEMENTS}>
            <JudgementClientDetails />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize
      key="judgement-client-list"
      permissions={[JUDGEMENT_MODULE_ACCESS]}
      hasAccess={(user) => !user?.hasPermissions([JUDGMENTS_MODULE_ADMIN_ACCESS])}
    >
      <Route
        path={ROUTE_JUDGEMENT_CLIENT_LIST}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_JUDGEMENTS}>
            <JudgementClientListView />
          </ModuleGuard>
        )}
      />
    </Authorize>
  ]
});
