import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Executor, { createExecutor } from 'modules/Executor/model/Executor';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteExecutor } from 'modules/Executor/breadcrumbs';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { deleteExecutorToastSuccess } from 'modules/Executor/toasts';
import { push } from 'connected-react-router';
import { ROUTE_EXECUTORS_ADMINS } from 'modules/Executor/routes';
import Alert from 'modules/Layout/component/Alert';
import ExecutorDetails from 'modules/Executor/component/Details/Admin';
import { deleteAdminExecutor, fetchAdminExecutor } from 'modules/Executor/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import DetailsOrderList from 'modules/ExecutorOrder/component/ExecutorDetailsOrderList';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import DetailsChangesExecutor from 'modules/Executor/component/Details/Admin/Changes/Executor';
import DetailsChangesExecutorOrder from 'modules/Executor/component/Details/Admin/Changes/ExecutorOrder';

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [executor, setExecutor] = useState<Executor>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteExecutorToast = () => dispatch(addToastAction(deleteExecutorToastSuccess()));

  const displayDeleteModal = () => setDisplayModal(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchAdminExecutor, id, cancelToken);

      if (cancelled) return;
      if (data) setExecutor(createExecutor(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executor) {
      const executorName = executor.name?.trim() || `NIP: ${executor.nip}`;
      const title = `${executorName} - szczegóły`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteExecutor(id, true, executorName)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor]);

  const deleteExecutorAction = async (executor_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteAdminExecutor, executor_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteExecutorToast();
      dispatch(push(ROUTE_EXECUTORS_ADMINS));
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  if (fetching) return <Loader />;
  if (!fetching && !executor && message)
    return (
      <div className="executor-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && executor) {
    return (
      <div className="row executor-details-view position-relative">
        {displayModal && (
          <GenericModalDelete
            value={executor}
            title="Usuwanie wykonawcy"
            content={(value) => `Czy na pewno chcesz usunąć wykonawcę - ${value.name ?? value.nip}`}
            onDeleteClick={(value) => deleteExecutorAction(value.id)}
            isOpen={displayModal}
            toggle={() => setDisplayModal(false)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <div className="col-6 user-details">
          <ExecutorDetails executor={executor} onDeleteClick={displayDeleteModal} />
        </div>
        <div className="col-12 user-details">
          <DetailsOrderList executor_id={id} executorName={executor?.name || executor?.nip} />
        </div>
        <div className="col-12 user-details">
          <DetailsChangesExecutor executor_id={id} />
        </div>
        <div className="col-12 user-details">
          <DetailsChangesExecutorOrder executor_id={id} />
        </div>
      </div>
    );
  }

  return null;
};

export default Details;
