import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import { ROUTE_EXECUTORS_REPORT_CLAIM } from 'modules/Executor/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate, hasFullAccess, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { setReportClaimsListParamsAction } from '../../../action/reportClaimsList';
import { ReportClaimList } from '../../../model/ReportClaim';

const ReportClaimsTable = () => {
  const dispatch = useDispatch();
  const { reportClaims, sort } = useSelector((state: RootState) => state.executor.reportClaimsList);
  const user = useSelector((state: RootState) => state.auth.user);

  const setParams = (payload: PartialSearchingProps) => dispatch(setReportClaimsListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Link className="fill-table-td" to={getPathUrl(ROUTE_EXECUTORS_REPORT_CLAIM, { report_claim_id: id })}>
      {value}
    </Link>
  );

  const cols: TableCol<ReportClaimList>[] = [
    {
      property: 'id',
      label: 'Numer zlecenia',
      sortable: true,
      value: (row) => redirectLink(String(row?.id), row?.id)
    },
    {
      property: 'first_name',
      label: 'Imię',
      sortable: true,
      value: (row) => redirectLink(row.first_name, row?.id)
    },
    {
      property: 'last_name',
      label: 'Nazwisko',
      sortable: true,
      value: (row) => redirectLink(row.last_name, row?.id)
    },
    {
      property: 'nip',
      label: 'NIP weryfikowanego wykonawcy',
      sortable: true,
      value: (row) => row.nip
    },
    {
      property: 'city',
      label: 'Miasto',
      sortable: true
    },
    {
      property: 'street',
      label: 'Ulica',
      sortable: true
    },
    {
      property: 'post_office',
      label: 'Miejscowość poczty',
      sortable: true
    },
    {
      property: 'status',
      label: 'Status wykonania raportu',
      sortable: true,
      value: (row) => row.status.name
    },
    {
      property: 'created_at',
      label: 'Data zlecenia raportu',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.created_at, formatDate)
    },
    {
      property: 'updated_at',
      label: 'Data ostatniej aktualizacji raportu',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.updated_at, formatDate)
    }
  ];

  return (
    <div className="position-relative">
      {!hasFullAccess(user) && (
        <div className="position-absolute" style={{ top: '-2.5rem', backgroundColor: '#fff', color: '#666666' }}>
          <div className="p-2 font-16" style={{ fontWeight: 500 }}>
            Wyszukani wykonawcy
          </div>
        </div>
      )}
      <Table cols={cols} rows={reportClaims} sort={sort} onSort={(params) => setParams({ sort: params })} />
    </div>
  );
};

export default ReportClaimsTable;
