import ActionDelete from 'modules/Layout/component/Action/Delete';
import TrashIcon from 'modules/Layout/component/Icon/Trash';
import Listing from 'modules/Layout/component/Listing';
import Table, { TableCol } from 'modules/Layout/component/Table';
import AddFilesDragDrop, { IFile } from 'modules/Shared/helper/Files/AddFilesDragDrop/AddFilesDragDrop';
import React, { useState } from 'react';
import { Label } from 'reactstrap';

const useManageFileAttachments = (currentActiveFiles: { id: number; name: string }[]) => {
  const [filesObj, setFilesObj] = useState<{ files: IFile[] }>({ files: [] });
  const [filesToRemove, setFilesToRemove] = useState<{ id: number; name: string }[]>([]);

  const clearValues = () => {
    setFilesToRemove([]);
    setFilesObj({ files: [] });
  };

  //   const currentActiveFilesColsActions = function Actions(row: { id: number; name: string }) {
  //     return (
  //       <div className="actions-wrapper">
  //         {/* <Authorize>
  //             <ActionUpdate
  //               className="mx-1"
  //               title="Pobierz plik"
  //               label={<DownloadIcon height="20px" />}
  //               onClick={() => orderDownloadFileRequest(row.id)}
  //             />
  //           </Authorize> */}
  //         <ActionDelete className="mx-1" title="Usuń plik" onClick={() => setFilesToRemove([...filesToRemove, row])} />
  //       </div>
  //     );
  //   };

  //   const activeAwatingAddingColsActions = function Actions(row: { id: number; name: string }) {
  //     return (
  //       <div className="actions-wrapper">
  //         <ActionDelete
  //           className="mx-1"
  //           title="Usuń plik"
  //           onClick={() => setFilesObj({ files: filesObj.files.filter((el) => parseInt(el.id) !== row.id) })}
  //         />
  //       </div>
  //     );
  //   };

  //   const activeAwatingRemovalColsActions = function Actions(row: { id: number; name: string }) {
  //     return (
  //       <div className="actions-wrapper">
  //         <ActionDelete
  //           className="mx-1"
  //           title="Usuń plik"
  //           onClick={() => setFilesToRemove(filesToRemove.filter((el) => el.id !== row.id))}
  //         />
  //       </div>
  //     );
  //   };

  const filterCurrentActiveFiles = currentActiveFiles.filter(
    (el) => filesToRemove.findIndex((obj) => obj.id === el.id) === -1
  );

  const filesCols: TableCol<{ id: number; name: string }>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'actions',
      label: filterCurrentActiveFiles.length ? 'Akcje' : '',
      value: function Actions(row: { id: number; name: string }) {
        return (
          <div className="actions-wrapper">
            {/* <Authorize>
                        <ActionUpdate
                          className="mx-1"
                          title="Pobierz plik"
                          label={<DownloadIcon height="20px" />}
                          onClick={() => orderDownloadFileRequest(row.id)}
                        />
                      </Authorize> */}
            <ActionDelete
              className="mx-1"
              title="Usuń plik"
              onClick={() => setFilesToRemove([...filesToRemove, row])}
            />
          </div>
        );
      }
    }
  ];

  const jsxAttachmentsDropbox = (
    <>
      <Label className="mt-4">Załączniki</Label>
      <p className="h5 mr-2">Obecnie wysyłane załączniki</p>
      <div className="pzpeu-table-list inside-card-box mb-0 mr-0 ml-0">
        <Listing table={<Table cols={filesCols} rows={filterCurrentActiveFiles} />} />
      </div>

      <div className="row justify-content-between mt-3">
        <div className="col-xl-5">
          <p className="h5 mr-2">Załączniki oczekujące dodania</p>
          <div className="mb-2 position-relative">
            <AddFilesDragDrop values={filesObj} setValues={setFilesObj} />
          </div>
        </div>
        <div className="col-xl-5">
          <p className="h5 mr-2">Załączniki oczekujące usunięcia</p>
          {filesToRemove.length ? (
            filesToRemove.map((file) => (
              <div key={file.id} className="my-2 d-flex flex-wrap">
                <span className="mr-2">{file.name}</span>
                <ActionDelete
                  label={<TrashIcon height="20px" />}
                  title="Usuń plik"
                  onClick={() => setFilesToRemove(filesToRemove.filter((el) => el.id !== file.id))}
                />
              </div>
            ))
          ) : (
            <div className="my-2 d-flex flex-wrap">Brak</div>
          )}
        </div>
      </div>
    </>
  );

  return {
    jsxAttachmentsDropbox,
    filesObj,
    filesToRemove,
    clearValues
  };
};

export default useManageFileAttachments;
