import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { ProceedingsCPVValues, ProceedingSendRequestValues } from 'modules/Proceedings/type';
import ForeignProceedings from './model/ForeignProceedings';
import { ForeignProceedingsApiImportsListValues, ForeignProceedingsImportsListValues } from './model/imports';
import { FetchExecutorRequestDetailsResponse } from '../Office/repository';

export const API_FOREIGN_PROCEEDINGS = '/api/foreign-proceedings';
export const API_FOREIGN_PROCEEDINGS_ADMIN = '/api/admin/foreign-proceedings';
export const API_FOREIGN_PROCEEDINGS_IMPORTS = '/api/admin/foreign-proceedings/import-nspa';
export const API_FOREIGN_PROCEEDINGS_IMPORT = '/api/admin/foreign-proceedings/imports';
export const API_FOREIGN_PROCEEDINGS_API_IMPORT = '/api/admin/foreign-proceedings/api-imports';
export const API_FOREIGN_PROCEEDINGS_IMPORT_DETAILS = '/api/admin/foreign-proceedings/imports/:import_id';
export const API_FOREIGN_PROCEEDINGS_SOURCES = '/api/foreign-proceedings/sources';
export const API_FOREIGN_PROCEEDINGS_SOURCES_ADMIN = '/api/admin/foreign-proceedings/sources';
export const API_FOREIGN_PROCEEDINGS_PROCEDURES = '/api/foreign-proceedings/procedures';
export const API_FOREIGN_PROCEEDINGS_PROCEDURES_ADMIN = '/api/admin/foreign-proceedings/procedures';
export const API_DICTIONARIES_COUNTRIES = '/api/dictionaries/countries';
export const API_FOREIGN_PROCEEDINGS_INDUSTRY_TYPES = '/api/foreign-proceedings/industry-types';
export const API_FOREIGN_PROCEEDINGS_INDUSTRY_TYPES_ADMIN = '/api/admin/foreign-proceedings/industry-types';
export const API_FOREIGN_PROCEEDINGS_KEYWORDS_ALL = '/api/foreign-proceedings/keywords/all';
export const API_FOREIGN_PROCEEDINGS_DETAILS = '/api/foreign-proceedings/:id';
export const API_FOREIGN_PROCEEDINGS_DETAILS_ADMIN = '/api/admin/foreign-proceedings/:id';
export const API_FOREIGN_PROCEEDINGS_DETAILS_SEND_REQUEST =
  '/api/documents/proceedings/:id/main_document_explanation/send';
export const API_FOREIGN_PROCEEDINGS_DETAILS_DOWNLOAD_REQUEST =
  '/api/documents/proceedings/:id/main_document_explanation/download';
export const API_FOREIGN_PROCEEDINGS_FAVOURITES = '/api/foreign-proceedings/saved_proceedings';
export const API_FOREIGN_PROCEEDINGS_FAVOURITES_ADMIN = '/api/admin/foreign-proceedings/saved_proceedings';
export const API_FOREIGN_PROCEEDINGS_FAVOURITE = '/api/foreign-proceedings/saved_proceedings/:proceeding_id';
export const API_FOREIGN_PROCEEDINGS_FAVOURITE_ADMIN =
  '/api/admin/foreign-proceedings/saved_proceedings/:proceeding_id';
export const API_FOREIGN_PROCEEDINGS_CPVs = '/api/foreign-proceedings/:proceeding_id/cpv_codes ';
export const API_FOREIGN_PROCEEDINGS_CPV = '/api/foreign-proceedings/:proceeding_id/cpv_codes/:id ';

export type FetchForeignProceedings = {
  data: ForeignProceedings[];
  meta: PaginationMeta;
  links: any;
};

export type FetchForeignProceedingsSources = { slug: string; name: string }[];
export type FetchForeignProceedingsProcedures = { slug: string; name: string }[];
export type FetchDictionariesCountries = { data: { [key: string]: string }[] };
export type FetchForeignProceedingsIndustryTypes = {
  id: number;
  name: string;
  subtypes?: { id: number; name: string }[];
}[];

export const fetchForeignProceedings = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchForeignProceedings> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS, null, params), { cancelToken });

export const fetchForeignProceedingsAdmin = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchForeignProceedings> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_ADMIN, null, params), { cancelToken });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchForeignProceedingsSources = (cancelToken?: any): AxiosPromise<FetchForeignProceedingsSources> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_SOURCES, null), { cancelToken });

export const fetchForeignProceedingsSourcesAdmin = (cancelToken?: any): AxiosPromise<FetchForeignProceedingsSources> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_SOURCES_ADMIN, null), { cancelToken });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchForeignProceedingsProcedures = (cancelToken?: any): AxiosPromise<FetchForeignProceedingsProcedures> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_PROCEDURES, null), { cancelToken });

export const fetchForeignProceedingsProceduresAdmin = (
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsProcedures> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_PROCEDURES_ADMIN, null), { cancelToken });

export const fetchDictionariesCountries = (cancelToken?: any): AxiosPromise<FetchDictionariesCountries> =>
  axios.get(getAPIUrl(API_DICTIONARIES_COUNTRIES, null), { cancelToken });

export const fetchForeignProceedingsIndustryTypes = (
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsIndustryTypes> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_INDUSTRY_TYPES, null), { cancelToken });

export const fetchForeignProceedingsIndustryTypesAdmin = (
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsIndustryTypes> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_INDUSTRY_TYPES_ADMIN, null), { cancelToken });

export const fetchForeignProceedingsAllKeywords = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity[] }> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_KEYWORDS_ALL, null, params), { cancelToken });

export type FetchForeignProceedingsDetails = {
  data: ForeignProceedings;
};

export const fetchForeignProceedingsDetails = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS, { id }), { cancelToken });

export const fetchForeignProceedingsAdminDetails = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS_ADMIN, { id }), { cancelToken });

export const fetchForeignProceedingsDetailsAdmin = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS_ADMIN, { id }), { cancelToken });

export const deleteForeignProceedings = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS, { id }), { cancelToken });

export const createNewForeignProceedings = (
  data: ForeignProceedings,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS), { ...data }, { cancelToken });

export const updateForeignProceedingsAdmin = (
  id: string | number,
  data: ForeignProceedings,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.put(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS_ADMIN, { id }), data, { cancelToken });

export const sendRequestForeignProceedings = (
  id: string | number,
  data: ProceedingSendRequestValues,
  cancelToken?: any
): AxiosPromise => axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS_SEND_REQUEST, { id }), data, { cancelToken });

export const downloadRequestForeignProceedings = (
  id: string | number,
  data: ProceedingSendRequestValues,
  cancelToken?: any
): AxiosPromise =>
  axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS_DETAILS_DOWNLOAD_REQUEST, { id }), data, {
    responseType: 'blob',
    cancelToken
  });

export const manageForeignProceedingsFav = (
  proceeding_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS_FAVOURITE, { proceeding_id }), {}, { cancelToken });

export const manageForeignProceedingsFavAdmin = (
  proceeding_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsDetails> =>
  axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS_FAVOURITE_ADMIN, { proceeding_id }), {}, { cancelToken });

export const fetchForeignProceedingsCPV = (
  proceeding_id: number | string,
  cancelToken?: any
): AxiosPromise<{ data: ProceedingsCPVValues[] }> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_CPVs, { proceeding_id }), { cancelToken });

export type FetchForeignProceedingsImportsResponse = {
  data: ForeignProceedingsImportsListValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchImports = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsImportsResponse> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_IMPORT, null, params), { cancelToken });

export type FetchForeignProceedingsApiImportsResponse = {
  data: ForeignProceedingsApiImportsListValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchApiImports = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchForeignProceedingsApiImportsResponse> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_API_IMPORT, null, params), { cancelToken });

export const importForeignProceedingsExcelFile = (
  file: FormData,
  cancelToken?: any
): AxiosPromise<FetchExecutorRequestDetailsResponse> =>
  axios.post(getAPIUrl(API_FOREIGN_PROCEEDINGS_IMPORTS), file, { cancelToken });

export const fetchImport = (
  import_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: ForeignProceedingsImportsListValues }> =>
  axios.get(getAPIUrl(API_FOREIGN_PROCEEDINGS_IMPORT_DETAILS, { import_id }), { cancelToken });
