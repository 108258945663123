import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';
import { ifContainsDateFormat, formatDate } from 'modules/Shared/helper/utils';

export interface Props {
  executor: ExecutorClient;
}

const ExecutorDataClientDataset: React.FC<Props> = ({ executor }): JSX.Element => {
  const { executor_data } = executor;

  const addressValue =
    executor_data?.street || executor_data?.postal_code || executor_data?.voivodeship ? (
      <>
        {executor_data?.street ? <div>{executor_data.street}</div> : null}
        <div>
          {executor_data?.postal_code ? <>{executor_data.postal_code}</> : null}
          {executor_data?.city ? <> {executor_data.city}</> : null}
        </div>
        {executor_data?.voivodeship ? <div>{executor_data.voivodeship}</div> : null}
      </>
    ) : null;

  const fields = [
    {
      label: 'Data aktualizacji',
      value: executor_data?.last_verification_date || 'nie dotyczy'
    },
    {
      label: 'Nazwa rejestrowa',
      value: executor_data?.name || 'nie dotyczy'
    },
    {
      label: 'Adres siedziby',
      valueClassNameParent: 'd-flex flex-wrap align-items-start',
      valueClassName: 'flex-grow-1',
      value: addressValue || 'nie dotyczy'
    },
    {
      label: 'KRS',
      value: executor_data?.krs || 'nie dotyczy'
    },
    {
      label: 'NIP',
      value: executor_data?.nip || 'nie dotyczy'
    },
    {
      label: 'Data wpisu do KRS lub CEIDG',
      value: ifContainsDateFormat(executor_data?.business_start_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Status wpisu w rejestrze podmiotów gospodarki narodowej (REGON)',
      value: executor_data?.regon_status || 'nie dotyczy'
    },
    {
      label: 'Informacje dotyczące upadłości',
      value: executor_data?.bankruptcy_info || 'nie dotyczy'
    }
  ];

  if (executor_data.consumer_bankruptcy_info) {
    fields.push({
      label: 'Upadłość konsumencka',
      value: executor_data.consumer_bankruptcy_info
    });
  }

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientDataset;
