import React from 'react';
import { Menu } from 'app/menu';
import JudgementsIcon from 'modules/Layout/component/Icon/Judgements';
import { ROUTE_JUDGEMENT_CLIENT_LIST } from 'modules/JudgementClient/routes';
import User from 'modules/User/model/User';
import { ROUTE_CLIENT_CLAUSES } from 'modules/ClauseClient/routes';
import { JUDGEMENT_MODULE_ACCESS } from './permissions';
import { JUDGMENTS_MODULE_ADMIN_ACCESS } from '../Judgement/permissions';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

const MenuJudgmentClientShared: Menu[] = [
  {
    key: 'judgment-client-nested',
    type: 'nested',
    title: 'Rejestr klauzul',
    icon: <JudgementsIcon />,
    navLevel: 'second',
    href: '',
    hasAccess: (user: User): boolean =>
      !user?.hasPermissions([JUDGMENTS_MODULE_ADMIN_ACCESS]) && user?.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS]),
    permissions: [JUDGEMENT_MODULE_ACCESS],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'clauses-client-index',
        type: 'link',
        title: 'Rejestr klauzul niedozwolonych',
        href: ROUTE_CLIENT_CLAUSES
      },
      {
        key: 'judgment-client-index',
        type: 'link',
        title: 'Orzeczenia sądowe',
        href: ROUTE_JUDGEMENT_CLIENT_LIST
      }
    ]
  }
];

export default MenuJudgmentClientShared;
