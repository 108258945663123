import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import ForeignProceedings, { foreignProceedingsStatus } from '../../../model/ForeignProceedings';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';
// eslint-disable-next-line import/no-cycle
import {
  ROUTE_FOREIGN_PROCEEDING,
  ROUTE_FOREIGN_PROCEEDING_ADMIN,
  ROUTE_FOREIGN_PROCEEDINGS_UPDATE
} from '../../../routes';
import { FOREIGN_PROCEEDINGS_DELETE, FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS } from '../../../permissions';

type Props = {
  onDeleteClick?: (proceedings: ForeignProceedings) => void;
  isAdmin?: boolean;
  redirectUrl?: string;
  paramsUrl?: Record<string, string>;
  manageFavouriteAction?: (proceeding_id: number, isAdding?: boolean) => void;
};

const ForeignProceedingsTable: React.FC<Props> = ({
  onDeleteClick,
  isAdmin,
  redirectUrl,
  manageFavouriteAction,
  paramsUrl = {}
}) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);

  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setForeignProceedingsListParamsAction(payload, isAdmin));

  const detailsRoute = isAdmin ? ROUTE_FOREIGN_PROCEEDING_ADMIN : ROUTE_FOREIGN_PROCEEDING;

  const redirectLink = (value: string, id: number) => (
    <Link className="fill-table-td" to={getPathUrl(redirectUrl ?? detailsRoute, { id }, paramsUrl)}>
      {value}
    </Link>
  );

  const cols: TableCol<ForeignProceedings>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'slug_source',
      label: 'Rynek',
      sortable: true,
      value: (row) => row.source_name
    },
    {
      property: 'ordered_by',
      label: 'Zamawiający',
      sortable: true,
      value: (row) => row.ordered_by
    },
    {
      property: 'countries',
      label: 'Kraj',
      sortable: false,
      value: (row) => row.countries.map((country) => `${country.country_name}${row.countries.length > 1 ? ', ' : ''}`)
    },
    {
      property: 'published_date',
      label: 'Data publikacji',
      sortable: true,
      value: (row) => ifContainsDateFormat(row?.published_date, formatDate)
    },
    {
      property: 'deadline_date',
      label: 'Termin składania ofert',
      sortable: true,
      value: (row) => ifContainsDateFormat(row?.deadline_date, formatDatetimeSeconds)
    },
    {
      property: 'status',
      label: 'Status',
      sortable: true,
      value: (row) => foreignProceedingsStatus(row.status)
    },
    {
      property: 'procedure',
      label: 'Procedura',
      sortable: true,
      value: (row) => `${row.procedure} (${row.platform_mode})`
    }
  ];

  const actionSavedBtn = (id: number) => (
    <ActionUpdate
      title="Dodaj do ulubionych postępowań"
      label={<i className="far font-20 fa-heart text-danger" />}
      onClick={() => manageFavouriteAction(id, true)}
    />
  );

  const actionNotSavedBtn = (id: number) => (
    <ActionUpdate
      title="Usuń z ulubionych postępowań"
      label={<i className="fas font-20 fa-heart text-danger" />}
      onClick={() => manageFavouriteAction(id)}
    />
  );

  const actionAdminCol: TableCol<ForeignProceedings>[] = [
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]}>
              <ActionUpdate
                title="Edytuj postępowanie"
                label={<EditIcon height="20px" />}
                to={getPathUrl(ROUTE_FOREIGN_PROCEEDINGS_UPDATE, { id: row.id })}
              />
              {row?.is_saved ? actionNotSavedBtn(row.id) : actionSavedBtn(row.id)}
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[FOREIGN_PROCEEDINGS_DELETE]}>
                <ActionDelete className="ml-1" title="Usuń postępowanie" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  const actionClientCol: TableCol<ForeignProceedings>[] = [
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">{row?.is_saved ? actionNotSavedBtn(row.id) : actionSavedBtn(row.id)}</div>
        );
      }
    }
  ];

  const tableCols = [...cols];

  let finalCols;

  if (isAdmin) {
    finalCols = [...tableCols, ...actionAdminCol];
  } else if (manageFavouriteAction) {
    finalCols = [...tableCols, ...actionClientCol];
  } else {
    finalCols = [...tableCols];
  }

  return <Table cols={finalCols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ForeignProceedingsTable;
