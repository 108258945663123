import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteUserToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja użytkownika',
  body: 'Użytkownik został usunięty',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateUserToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja użytkownika',
  body: 'Użytkownik został zaktualizowany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createUserToastSuccess = (): AddToastPayload => ({
  header: 'Tworzenie użytkownika',
  body: 'Użytkownik został pomyślnie utworzony.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const acceptUserNipToastSuccess = (): AddToastPayload => ({
  header: 'Potwierdzenie numeru NIP z Asystenta postępowań',
  body: 'Potwierdzenie numeru NIP z Asystenta postępowań zostało zmienione',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
