import React from 'react';
import { EXECUTORS_INDEX, EXECUTORS_ADD, EXECUTOR_EXPLANATION_EDIT } from 'modules/Executor/permissions';
import {
  ROUTE_EXECUTOR_ADMINS_CREATE,
  ROUTE_EXECUTORS_ADMINS,
  ROUTE_EXECUTORS,
  ROUTE_EXECUTOR_ADMINS_SECTION_EXPLANATION,
  ROUTE_EXECUTORS_REPORT_CLAIMS
} from 'modules/Executor/routes';
import { Menu } from 'app/menu';
import User from 'modules/User/model/User';
import { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';
import BaseIcon from '../Layout/component/Icon/Base';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

const MenuExecutorShared: Menu[] = [
  {
    key: 'executors-section',
    type: 'section',
    title: 'Wykonawcy',
    hasAccess: (user: User): boolean => user?.role?.slug === ROLE_ADMIN
  },
  {
    key: 'executors-nested',
    type: 'nested',
    title: 'Baza wykonawców',
    icon: <BaseIcon />,
    navLevel: 'second',
    href: '',
    permissions: [EXECUTORS_INDEX, EXECUTORS_ADD],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'executors-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_EXECUTORS_ADMINS,
        permissions: [EXECUTORS_INDEX]
      },
      {
        key: 'executors-create',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_EXECUTOR_ADMINS_CREATE,
        permissions: [EXECUTORS_ADD]
      },
      {
        key: 'executors-explanation',
        type: 'link',
        title: 'Wyjaśnienia sekcji raportu',
        href: ROUTE_EXECUTOR_ADMINS_SECTION_EXPLANATION,
        permissions: [EXECUTOR_EXPLANATION_EDIT]
      }
    ]
  },
  {
    key: 'executors-client-nested',
    type: 'nested',
    navLevel: 'second',
    title: 'Baza wykonawców',
    icon: <BaseIcon />,
    href: '',
    hasAccess: (user) => user?.role?.slug === ROLE_CLIENT && user.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS]),
    children: [
      {
        key: 'executors-client',
        type: 'link',
        title: 'Raporty',
        href: ROUTE_EXECUTORS
      },
      {
        key: 'executors-client-report-claims',
        type: 'link',
        title: 'Pogłębiona analiza',
        href: ROUTE_EXECUTORS_REPORT_CLAIMS
      }
    ]
  }
];

/*  !!user?.modules?.find((module) => module?.slug === MODULE_EXECUTOR) */

export default MenuExecutorShared;
