import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { OfficeEntity } from 'modules/Office/model/Office';
import {
  FetchEmailRequest,
  OfficeExecutorRequestChannelsValue,
  OfficeExecutorRequestReceivedValueSend,
  OfficeExecutorRequestsValue,
  OfficeExecutorRequestValue,
  OfficeFormValues,
  ResendEmailRequest
} from 'modules/Office/type';
import { OfficeImportsListValues } from 'modules/Office/model/Imports';

export const API_OFFICES = '/api/offices';
export const API_OFFICES_EXTENDED = '/api/offices_extended';
export const API_OFFICES_EXTENDED_CATEGORIES = '/api/offices_extended/categories';
export const API_OFFICES_CRUD = '/api/offices/:id';
export const API_OFFICES_EXTENDED_CRUD = '/api/offices_extended/:id';
export const API_OFFICES_EXECUTOR_REQUESTS = '/api/offices/:office_id/executor_requests';
export const API_OFFICES_EXECUTOR_REQUEST = '/api/offices/:office_id/executor_requests/:executor_request_id';
export const API_OFFICES_EXECUTOR_REQUEST_CHANNELS = '/api/offices/executor_request_channels';
export const API_OFFICES_EXECUTOR_REQUEST_DOWNLOAD =
  '/api/offices/:office_id/executor_requests/:executor_request_id/download/:file_id';
export const API_OFFICES_EXECUTOR_REQUEST_RECEIVED =
  '/api/offices/:office_id/executor_requests/:executor_request_id/received';
export const API_OFFICES_IMPORTS = '/api/offices/imports';
export const API_OFFICES_IMPORTS_DETAILS = '/api/offices/imports/:import_id';
export const API_OFFICES_EMAIL = '/api/offices/executor_request_templates';
export const API_OFFICES_FORCE_RESEND_EMAIL = '/api/offices/send_executor_requests';
export const API_OFFICE_REGON = '/api/api-gus/:regon';

export type FetchOfficesResponse = {
  data: OfficeEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchOfficesExtendedCategories = { data: { slug: string; name: string }[] };

export const fetchOffices = (params: SearchParams = {}): AxiosPromise<FetchOfficesResponse> =>
  axios.get(getAPIUrl(API_OFFICES, null, params));

export const fetchOfficesExtended = (params: SearchParams = {}): AxiosPromise<FetchOfficesResponse> =>
  axios.get(getAPIUrl(API_OFFICES_EXTENDED, null, params));

export const fetchOfficesExtendedCategories = (cancelToken?: any): AxiosPromise<FetchOfficesExtendedCategories> =>
  axios.get(getAPIUrl(API_OFFICES_EXTENDED_CATEGORIES, null), { cancelToken });

export type FetchOfficeResponse = {
  data: OfficeEntity;
};

export const fetchOffice = (id: string | number, cancelToken?: any): AxiosPromise<FetchOfficeResponse> =>
  axios.get(getAPIUrl(API_OFFICES_CRUD, { id }), { cancelToken });

export const fetchOfficeExtended = (id: string | number, cancelToken?: any): AxiosPromise<FetchOfficeResponse> =>
  axios.get(getAPIUrl(API_OFFICES_EXTENDED_CRUD, { id }), { cancelToken });

export const deleteOffice = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_OFFICES_CRUD, { id }), { cancelToken });

export const createNewOffice = (data: OfficeFormValues, cancelToken?: any): AxiosPromise<FetchOfficeResponse> =>
  axios.post(getAPIUrl(API_OFFICES), { ...data }, { cancelToken });

export const updateOffice = (
  id: number | string,
  data: OfficeFormValues,
  cancelToken?: any
): AxiosPromise<FetchOfficeResponse> => axios.put(getAPIUrl(API_OFFICES_CRUD, { id }), data, { cancelToken });

export type FetchOfficeImportsResponse = {
  data: OfficeImportsListValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchImports = (params: SearchParams = {}, cancelToken?: any): AxiosPromise<FetchOfficeImportsResponse> =>
  axios.get(getAPIUrl(API_OFFICES_IMPORTS, null, params), { cancelToken });

export type FetchExecutorRequestsListResponse = {
  data: OfficeExecutorRequestsValue[];
  meta: PaginationMeta;
  links: any;
};

export const fetchExecutorRequestsList = (
  office_id: number | string,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchExecutorRequestsListResponse> =>
  axios.get(getAPIUrl(API_OFFICES_EXECUTOR_REQUESTS, { office_id }, params), { cancelToken });

export type FetchExecutorRequestDetailsResponse = {
  data: OfficeExecutorRequestValue;
};

export const fetchExecutorRequestDetails = (
  office_id: number | string,
  executor_request_id: number | string,
  cancelToken?: any
): AxiosPromise<FetchExecutorRequestDetailsResponse> =>
  axios.get(getAPIUrl(API_OFFICES_EXECUTOR_REQUEST, { office_id, executor_request_id }), { cancelToken });

export const fetchExecutorRequestFile = (
  office_id: number | string,
  executor_request_id: number | string,
  file_id: number | string,
  cancelToken?: any
): AxiosPromise =>
  axios.get(getAPIUrl(API_OFFICES_EXECUTOR_REQUEST_DOWNLOAD, { office_id, executor_request_id, file_id }), {
    responseType: 'blob',
    cancelToken
  });

export const fetchExecutorRequestChannels = (
  cancelToken?: any
): AxiosPromise<{ data: OfficeExecutorRequestChannelsValue[] }> =>
  axios.get(getAPIUrl(API_OFFICES_EXECUTOR_REQUEST_CHANNELS), { cancelToken });

export const executorRequestReceived = (
  office_id: number | string,
  executor_request_id: number | string,
  data: OfficeExecutorRequestReceivedValueSend,
  cancelToken?: any
): AxiosPromise<FetchExecutorRequestDetailsResponse> =>
  axios.post(getAPIUrl(API_OFFICES_EXECUTOR_REQUEST_RECEIVED, { office_id, executor_request_id }), data, {
    cancelToken
  });

export const importOfficeExcelFile = (
  file: FormData,
  cancelToken?: any
): AxiosPromise<FetchExecutorRequestDetailsResponse> =>
  axios.post(getAPIUrl(API_OFFICES_IMPORTS), file, { cancelToken });

export const fetchImport = (
  import_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: OfficeImportsListValues }> =>
  axios.get(getAPIUrl(API_OFFICES_IMPORTS_DETAILS, { import_id }), { cancelToken });

export const fetchEmail = (cancelToken?: any): AxiosPromise<{ data: FetchEmailRequest }> =>
  axios.get(getAPIUrl(API_OFFICES_EMAIL), { cancelToken });

export const updateEmail = (data: FetchEmailRequest, cancelToken?: any): AxiosPromise<{ data: FetchEmailRequest }> =>
  axios.post(getAPIUrl(API_OFFICES_EMAIL), data, { cancelToken });

export const resendEmail = (data: ResendEmailRequest, cancelToken?: any): AxiosPromise =>
  axios.post(getAPIUrl(API_OFFICES_FORCE_RESEND_EMAIL), data, { cancelToken });

export const fetchOfficeFromRegon = (regon: string): AxiosPromise<any> =>
  axios.get(getAPIUrl(API_OFFICE_REGON, { regon }));
