import _get from 'lodash/get';
import { SearchParams, SelectOption } from 'modules/Shared/type';
import { FilterInputType } from 'modules/Layout/type';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchDictionariesCountries,
  fetchForeignProceedingsIndustryTypes,
  fetchForeignProceedingsIndustryTypesAdmin,
  fetchForeignProceedingsProcedures,
  fetchForeignProceedingsProceduresAdmin,
  fetchForeignProceedingsSources,
  fetchForeignProceedingsSourcesAdmin
} from '../../../../repository';
import { foreignProceedingStatusOptions } from '../../../../model/ForeignProceedings';
import { RootState } from '../../../../../../app/reducer';

export interface Props {
  values: SearchParams;
  isAdmin?: boolean;
}

type SlugType = { slug: string; name: string };
type IndustryType = {
  id: number;
  name: string;
  subtypes?: IndustrySubType[];
};

type IndustrySubType = {
  id: number;
  name: string;
};

type DictionaryCountiesType = {
  [key: string]: string;
};

const useFilterValues = ({ values = {}, isAdmin = false }: Props) => {
  const { filter } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const [sourcesList, setSourcesList] = useState<SlugType[]>([]);
  const [proceduresList, setProceduresList] = useState<SlugType[]>([]);
  const [industryTypesList, setIndustryTypesList] = useState<IndustryType[]>([]);
  const [dictionaryCountiesList, setDictionaryCountiesList] = useState<DictionaryCountiesType[]>([]);
  const [industrySubTypesList, setIndustrySubTypesList] = useState<IndustrySubType[]>([]);
  const sourcesOptions: SelectOption[] = sourcesList.map((sourceItem) => {
    return {
      value: sourceItem.slug,
      label: sourceItem.name
    };
  });
  const proceduresOptions: SelectOption[] = proceduresList.map((procedureItem) => {
    return {
      value: procedureItem.slug,
      label: procedureItem.name
    };
  });
  const industryTypesOptions: SelectOption[] = industryTypesList.map((industryTypeItem) => {
    return {
      value: String(industryTypeItem.id),
      label: industryTypeItem.name
    };
  });
  const industrySubTypesOptions: SelectOption[] = industrySubTypesList.map((industrySubTypeItem) => {
    return {
      value: industrySubTypeItem.id,
      label: industrySubTypeItem.name
    };
  });
  const dictionaryCountiesOptions: SelectOption[] = Object.entries(dictionaryCountiesList).map(([key, value]) => {
    return {
      value: key,
      label: String(value)
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseSources = isAdmin
        ? await fetchForeignProceedingsSourcesAdmin()
        : await fetchForeignProceedingsSources();
      const responseProcedures = isAdmin
        ? await fetchForeignProceedingsProceduresAdmin()
        : await fetchForeignProceedingsProcedures();
      const responseIndustryTypes = isAdmin
        ? await fetchForeignProceedingsIndustryTypesAdmin()
        : await fetchForeignProceedingsIndustryTypes();
      const responseDictionaryCounties = await fetchDictionariesCountries();
      const newSources = responseSources.data;
      const newProcedures = responseProcedures.data;
      const newIndustryTypes = responseIndustryTypes.data;
      const newDictionaryCounties = responseDictionaryCounties.data.data;
      setSourcesList(newSources);
      setProceduresList(newProcedures);
      setIndustryTypesList(newIndustryTypes);
      setDictionaryCountiesList(newDictionaryCounties);
    };
    getValues().then((r) => r);
  }, [isAdmin]);

  useEffect(() => {
    const selectedIndustryType = industryTypesList.find((type) => type.id === Number(values.industry_type_id));
    if (selectedIndustryType?.subtypes.length > 0) {
      setIndustrySubTypesList(selectedIndustryType.subtypes);
    } else {
      const localStorageParams = localStorage.getItem(`localFilterParamsforeign_proceedings${isAdmin ? '_admin' : ''}`);
      setIndustrySubTypesList([]);
      if (localStorageParams) {
        const parsedParams = JSON.parse(localStorageParams);

        delete parsedParams.filter.industry_sub_type_id;
        localStorage.setItem(
          `localFilterParamsforeign_proceedings${isAdmin ? '_admin' : ''}`,
          JSON.stringify(parsedParams)
        );
      }
    }
  }, [industryTypesList, values, isAdmin]);

  const getInputs = (): FilterInputType[] => {
    const deadlineDateFrom = _get(filter, 'deadline_date_from') as string;
    const deadlineDateTo = _get(filter, 'deadline_date_to') as string;
    const publishedDateFrom = _get(filter, 'published_date_from') as string;
    const publishedDateTo = _get(filter, 'published_date_to') as string;

    const inputs: FilterInputType[] = [
      {
        type: 'text',
        property: 'name',
        label: 'Nazwa postępowania',
        value: _get(values, 'name')
      },
      {
        type: 'checkboxSelect',
        property: 'slug_source',
        label: 'Rynek',
        acceptValueAsString: true,
        options: [...sourcesOptions],
        value: _get(values, 'slug_source'),
        className: 'col-12 col-lg-6 col-xl-4'
      },
      {
        type: 'text',
        property: 'ordered_by',
        label: 'Zamawiający',
        value: _get(values, 'ordered_by')
      },
      {
        type: 'searchSelect',
        property: 'country_code',
        label: 'Kraj',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...dictionaryCountiesOptions
        ],
        value: _get(values, 'code')
      },
      {
        type: 'text',
        property: 'ref_nr',
        label: 'Numer referencyjny',
        value: _get(values, 'ref_nr')
      },
      {
        type: 'select',
        property: 'procedure',
        label: 'Procedura',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...proceduresOptions
        ],
        value: _get(values, 'procedure')
      },
      {
        type: 'date',
        property: 'deadline_date_from',
        label: 'Termin składania ofert od',
        value: deadlineDateFrom,
        className: 'col'
      },
      {
        type: 'date',
        property: 'deadline_date_to',
        label: 'Termin składania ofert do',
        value: deadlineDateTo,
        min: String(deadlineDateFrom),
        className: 'col'
      },
      {
        type: 'date',
        property: 'published_date_from',
        label: 'Data publikacji od',
        value: publishedDateFrom,
        className: 'col'
      },
      {
        type: 'date',
        property: 'published_date_to',
        label: 'Data publikacji do',
        value: publishedDateTo,
        min: String(publishedDateFrom),
        className: 'col'
      },
      {
        type: 'select',
        property: 'status',
        label: 'Status postępowania',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...foreignProceedingStatusOptions()
        ],
        value: _get(filter, 'status')
      },
      {
        type: 'select',
        property: 'industry_type_id',
        label: 'Branże',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...industryTypesOptions
        ],
        value: _get(values, 'industry_type_id')
      },
      {
        type: 'select',
        property: 'industry_sub_type_id',
        label: 'Branże podgrupa',
        acceptValueAsString: true,
        disabled: values.industry_type_id !== '' && industrySubTypesOptions.length <= 1,
        options: [
          {
            value: '',
            label: ''
          },
          ...industrySubTypesOptions
        ],
        value: _get(values, 'industry_sub_type_id')
      }
    ];

    if (isAdmin) {
      inputs.push({
        type: 'select',
        property: 'manually_modified',
        label: 'Zmodyfikowany ręcznie',
        value: _get(values, 'manually_modified'),
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: 'Wszystkie'
          },
          {
            value: 1,
            label: 'Zmodyfikowano'
          },
          {
            value: 0,
            label: 'Niemodyfikowane'
          }
        ]
      });
    }

    return inputs;
  };

  return { inputs: getInputs() };
};

export default useFilterValues;
