import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { FetchEmailRequest } from 'modules/Office/type';

export interface Props {
  values: FetchEmailRequest;
  setValues: React.Dispatch<React.SetStateAction<FetchEmailRequest>>;
  displayWysiwyg: () => JSX.Element;
  required?: boolean;
  errors?: ValidationErrors;
}

const Form: React.FC<Props> = (props): JSX.Element => {
  const { values, setValues, displayWysiwyg, required, errors, children } = props;
  const { subject } = values;

  return (
    <fieldset className="m-0 position-relative">
      {/* <FormGroup className={contentObj ? 'form-group-large-margins' : 'form-group-last'}> */}
      <FormGroup className="form-group-large-margins">
        <Label for="subject">{suffixLabel('Tytuł', required)}</Label>
        <Input
          type="text"
          id="subject"
          value={subject}
          onChange={(event) => setValues({ ...values, subject: event.target.value })}
          invalid={hasError(errors, 'subject')}
          required={required}
        />
        {hasError(errors, 'subject') && <FormFeedback>{getError(errors, 'subject')}</FormFeedback>}
      </FormGroup>
      <FormGroup className="form-group-last">
        {displayWysiwyg && displayWysiwyg()}

        {hasError(errors, 'content') && <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>}
      </FormGroup>
      {children}
    </fieldset>
  );
};

export default Form;
