import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';

import { ExecutorContract } from 'modules/Executor/model/ExecutorClient';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

const ExecutorContractsTable = ({ contracts }: { contracts: ExecutorContract[] }) => {
  const cols: TableCol<ExecutorContract>[] = [
    {
      property: 'order_object',
      label: 'Nazwa umowy',
      value: (row) => row.order_object
    },
    {
      property: 'organization_name',
      label: 'Zamawiający',
      value: (row) => row.organization_name
    },
    {
      property: 'organization_national_id',
      label: 'Identyfikator zamawiającego',
      value: (row) => row.organization_national_id
    },
    {
      property: 'organization_city',
      label: 'Miejscowość',
      value: (row) => row.organization_city
    },
    {
      property: 'notice_date',
      label: 'Data ogłoszenia',
      value: (row) => ifContainsDateFormat(row?.notice_date, formatDate)
    }
  ];

  return <Table cols={cols} rows={contracts} />;
};

export default ExecutorContractsTable;
