import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
// eslint-disable-next-line import/no-cycle
import {
  ROUTE_FOREIGN_PROCEEDING,
  ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS,
  ROUTE_FOREIGN_PROCEEDINGS_IMPORTS,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV
} from './routes';
import { FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS } from './permissions';

const breadcrumbRouteForeignProceedingsOpen = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka zagranicznych prowadzonych postępowań - lista',
    link: ROUTE_FOREIGN_PROCEEDINGS_OPEN
  }
];

export const breadcrumbRouteForeignProceedings = (): BreadcrumbItem[] => {
  return breadcrumbRouteForeignProceedingsOpen();
};

export const breadcrumbRouteForeignProceedingsDetails = (name?: string, id?: number): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedings(),
  {
    title: `${name ?? 'Wyszukiwarka zagranicznych postępowań'} - szczegóły`,
    link: getPathUrl(ROUTE_FOREIGN_PROCEEDING, { id })
  }
];

export const breadcrumbRouteForeignProceedingsUpdate = (name: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedingsDetails(name, id),
  {
    title: 'Edycja postępowania'
  }
];

export const breadcrumbRouteForeignProceedingsCreate = (): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedings(),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteForeignProceedingsOpenFav = (): BreadcrumbItem[] => [
  {
    title: 'Ulubione zagraniczne postępowania - lista',
    link: ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV
  }
];

export const breadcrumbRouteForeignProceedingsImports = (): BreadcrumbItem[] => [
  {
    title: 'Postępowania zagraniczne – Importuj postępowania NATO',
    link: ROUTE_FOREIGN_PROCEEDINGS_IMPORTS,
    permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
  }
];

export const breadcrumbRouteForeignProceedingsImport = (file_name: string): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedingsImports(),
  {
    title: `Importuj postępowania NATO ${file_name ? ` - ${file_name}` : ''}`
  }
];

export const breadcrumbRouteForeignProceedingsApiImports = (): BreadcrumbItem[] => [
  {
    title: 'Postępowania zagraniczne – Logi importów z API',
    link: ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS,
    permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
  }
];
