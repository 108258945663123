import React from 'react';
import { Menu } from 'app/menu';
import {
  ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV,
  ROUTE_FOREIGN_PROCEEDINGS_ALERTS,
  ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS,
  ROUTE_FOREIGN_PROCEEDINGS_IMPORTS,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV
} from './routes';
import { hasModule, MODULE_FOREIGN_PROCEEDINGS } from '../Module/model/Module';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';
import { FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS } from './permissions';
import GlobeSearchIcon from '../Layout/component/Icon/GlobeSearch';

const ForeignProceedingsOfficesShared: Menu[] = [
  {
    key: 'foreign-proceedings-client-menu',
    type: 'nested',
    navLevel: 'second',
    href: '#',
    isActive: (_match: null) => false,
    title: 'Wyszukiwarka zagranicznych postępowań',
    hasAccess: (user) =>
      hasModule(MODULE_FOREIGN_PROCEEDINGS, user) || user.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS]),
    icon: <GlobeSearchIcon />,
    children: [
      {
        key: 'foreign-proceedings-client-open-index',
        type: 'link',
        title: 'Prowadzone postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_OPEN,
        hasAccess: (user) => !user.hasPermissions([FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS])
      },
      {
        key: 'foreign-proceedings-open-list-admin-index',
        type: 'link',
        title: 'Prowadzone postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN,
        permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
      },
      {
        key: 'foreign-proceedings-open-favourite',
        type: 'link',
        title: 'Ulubione postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV,
        hasAccess: (user) => !user.hasPermissions([FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS])
      },
      {
        key: 'foreign-proceedings-open-favourite-admin',
        type: 'link',
        title: 'Ulubione postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV,
        permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
      },
      {
        key: 'foreign-proceedings-imports',
        type: 'link',
        title: 'Importuj postępowania NATO',
        href: ROUTE_FOREIGN_PROCEEDINGS_IMPORTS,
        permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
      },
      {
        key: 'foreign-proceedings-api-imports',
        type: 'link',
        title: 'Logi importów z API',
        href: ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS,
        permissions: [FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]
      },
      {
        key: 'foreign-proceedings-alerts-open-index',
        type: 'link',
        title: 'Alert postepowań',
        href: ROUTE_FOREIGN_PROCEEDINGS_ALERTS,
        hasAccess: (user) => !user.hasPermissions([FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS])
      }
    ]
  }
];

export default ForeignProceedingsOfficesShared;
