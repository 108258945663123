import { BreadcrumbItem } from 'modules/Layout/type';
import { PAYMENY_HISTORY_ALL, USERS_INDEX } from 'modules/User/permissions';
import { ROUTE_INVOICES, ROUTE_USER, ROUTE_USERS, ROUTE_USERS_ADMINS, ROUTE_USERS_DELETED } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbUsers = (isAdmin?: boolean): BreadcrumbItem => ({
  title: `${isAdmin ? 'Administratorzy' : 'Klienci'} - lista`,
  link: isAdmin ? ROUTE_USERS_ADMINS : ROUTE_USERS,
  permissions: [USERS_INDEX]
});

export const breadcrumbRouteUsers = (isAdmin?: boolean): BreadcrumbItem[] => [
  {
    title: `${isAdmin ? 'Administratorzy' : 'Klienci'} - lista`
  }
];

export const breadcrumbRouteExecutorsExtended = (): BreadcrumbItem[] => [
  {
    title: `Zlecenia z PA - lista`
  }
];

export const breadcrumbRouteUser = (id?: string | number, isAdmin?: boolean, userName?: string): BreadcrumbItem[] => [
  breadcrumbUsers(isAdmin),
  {
    title: `${userName ?? 'Użytkownik'} - szczegóły`,
    link: getPathUrl(ROUTE_USER, { id })
  }
];

export const breadcrumbRouteUsersDeleted = (): BreadcrumbItem[] => [
  {
    title: 'Usunięci klienci - lista',
    link: ROUTE_USERS_DELETED,
    permissions: [USERS_INDEX]
  }
];

export const breadcrumbRouteUserDeleted = (id?: string | number, userName?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteUsersDeleted(),
  {
    title: `${userName ?? 'Użytkownik'} - szczegóły`,
    link: getPathUrl(ROUTE_USER, { id })
  }
];

export const breadcrumbRouteUserCreate = (isAdmin?: boolean): BreadcrumbItem[] => [
  breadcrumbUsers(isAdmin),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteUserBasicUpdate = (id: number, isAdmin?: boolean, userName?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id, isAdmin, userName),
  {
    title: 'Edycja podstawowych danych'
  }
];

export const breadcrumbRouteUserCompanyUpdate = (id: number, userName?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id, false, userName),
  {
    title: 'Edycja danych rozliczeniowych'
  }
];

export const breadcrumbRouteUserRodoUpdate = (id: number, userName?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id, false, userName),
  {
    title: 'Edycja RODO'
  }
];

export const breadcrumbRouteUserAccessUpdate = (id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id),
  {
    title: 'Edycja modułów'
  }
];

export const breadcrumbRouteUserRoleUpdate = (id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id),
  {
    title: 'Edycja roli'
  }
];

export const breadcrumbRouteUserPaymentLog = (
  id: number | string,
  userName?: string,
  payment_number?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteUser(id, false, userName),
  {
    title: `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`
  }
];

export const breadcrumbRouteUserDeletedPaymentLog = (
  id: number | string,
  userName?: string,
  payment_number?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteUserDeleted(id, userName),
  {
    title: `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`
  }
];

export const breadcrumbRouteInvoicesRequired = (): BreadcrumbItem[] => [
  {
    title: 'Lista transakcji wymagających faktury',
    link: ROUTE_INVOICES,
    permissions: [PAYMENY_HISTORY_ALL]
  }
];

export const breadcrumbRouteInvoiceRequiredListPaymentLog = (payment_number?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteInvoicesRequired(),
  {
    title: `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`
  }
];
